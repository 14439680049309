let AMapScriptLoaded = false;
const AMAP_KEY = 'cfa72cf18bb21f3f420bcfd71072ab4e';

// 动态加载高德地图 JS SDK
function loadAMap() {
  return new Promise((resolve, reject) => {
    if (AMapScriptLoaded) {
      resolve(window.AMap);
      return;
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    // 使用2.0版本，并加载定位插件
    script.src = `https://webapi.amap.com/maps?v=2.0&key=${AMAP_KEY}&plugin=AMap.Geolocation`;
    script.onerror = (err) => reject(err);
    script.onload = () => {
      AMapScriptLoaded = true;
      resolve(window.AMap);
    };

    document.head.appendChild(script);
  });
}

// 获取当前定位信息
export async function getCurrentLocation() {
  const AMap = await loadAMap();
  return new Promise((resolve, reject) => {
    // 创建一个虚拟map容器用于定位（无需展示）
    const mapContainer = document.createElement('div');
    const map = new AMap.Map(mapContainer);

    map.plugin('AMap.Geolocation', () => {
      const geolocation = new AMap.Geolocation({
        enableHighAccuracy: true,
        timeout: 5000,
        showMarker: false,
        showCircle: false,
        showButton: false
      });

      geolocation.getCurrentPosition((status, result) => {
        if (status === 'complete') {
          console.log('定位成功，result: ', result)
          // 定位成功
          resolve(result);
        } else {
          console.log('定位失败，result: ', result)
          // 定位失败
          reject(new Error(result.message || '定位失败'));
        }
      });
    });
  });
}
