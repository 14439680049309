import { defineStore } from 'pinia'
import axios from 'axios'
import { useStoreStore } from '@/stores/store'
import { getCurrentLocation } from '@/utils/locationService'

export const useLocationStore = defineStore('location', {
  state: () => ({
    latitude: null,
    longitude: null,
    city: '',
    selectedCity: null,
    locationError: false,
    errorMessage: ''
  }),
  actions: {
    async fetchLocation() {
      this.locationError = false 
      this.errorMessage = ''
      // 使用高德定位
      try {
        const result = await getCurrentLocation();
        const { position } = result;
        this.latitude = position.lat;
        this.longitude = position.lng;
        console.log('定位成功，经纬度：', this.latitude, this.longitude)
      } catch (error) {
        console.error('Error fetching location:', error);
        // 根据错误信息进行判断，这里根据经验判断，实际可根据result.message更精确判断
        const errMsg = error.message || '';
        if (errMsg.includes('permission denied') || errMsg.includes('权限')) {
          this.locationError = true;
          this.errorMessage = '请打开位置信息(GPS)功能以获取当前位置，或者在上面的输入框中输入你当前的地址，输入格式为：上海xxx; 加入城市搜索更精确';
        } else if (errMsg.includes('timeout') || errMsg.includes('超时')) {
          // 使用默认坐标
          this.latitude = 30.156431;
          this.longitude = 120.143296;
          this.errorMessage = '定位超时，已使用默认位置';
        } else {
          this.errorMessage = '定位失败，下拉并打开位置功能重新定位，请在上面的输入框中输入你当前的地址';
        }
      }

      // 判断是否为测试环境
      const isTestEnv = process.env.VUE_APP_TEST_ENV === 'true';
      // if (isTestEnv) {
      //   console.log("isTestEnv: ", isTestEnv)
      //   this.latitude = 30.156431;
      //   this.longitude = 120.143296;
      // }
      
      try {
        const storeStore = useStoreStore()
        // 逆地理编码获取城市
        await storeStore.fetchGaodeGeo(this.longitude, this.latitude);
        const { city, location } = storeStore.gaodeGeo;
        if (city && location) {
          this.city = city;
          const [longitude, latitude] = location.split(',').map(Number);
          this.longitude = longitude;
          this.latitude = latitude;
          // 获取附近的门店列表
          await storeStore.fetchStores(this.city, '', this.longitude, this.latitude)
        } else {
          this.city = '杭州'; // 默认城市
          this.latitude = 30.156431;
          this.longitude = 120.143296;
          this.errorMessage = '无法获取城市信息，已使用默认城市。';
        }
      } catch (error) {
        console.error('Error fetching location:', error)
        this.city = '杭州'
        this.latitude = 30.156431;
        this.longitude = 120.143296;
        this.errorMessage = '无法获取城市信息，已使用默认城市。'
      }
    },
    async setSelectedCity(city) {
      this.selectedCity = city
      const storeStore = useStoreStore()
      await storeStore.fetchStores(city.name, '', city.lng, city.lat)
    }
  }
})